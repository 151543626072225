.container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  height: 60%;
}

.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  color: white;
  border-radius: 15px;
  padding: 0;
  margin: 0;
  gap: 15%;
  height: 100vh;

}

.range {
  z-index: 990000;
  max-width: 100%;
  height: 20px;
  background-color: #f1f1f1;
  border-radius: 15px;
  padding: 0;
  padding: 0px;
  transform: rotate(90deg);
  box-sizing: border-box;
  background: none;
}
.cube {
  width: 300px;
  height: 300px;
  position: relative;
  transform-style: preserve-3d;
  cursor: grab;
}

.cube:active {
  cursor: grabbing;
}

.face {
  position: absolute;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: auto;
}

.bg {
  background-color: #f1f1f1;
  border-radius: 15px;
  padding: 0;
  margin: 0;
 opacity: .7;
  transition: background 0.3s ease-in-out;
}

.face-link {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  transition: background 0.3s ease-in-out;
  
}

.btns {
  position: absolute;
  width: 30%;
  height: 30px;
  background-color: rgb(20, 170, 220);
  border: none;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: white;
  font-size: .8em;
  cursor: pointer;
  bottom: 10%;

}

.btns:hover,
.btns:focus,
.btns:active{
  
  background-color: rgb(15, 130, 180);
}

.front {
  transform: rotateY(0deg) translateZ(180px); /* Metade da largura/altura do cubo (300px) */
  background-color: #97620577;
}

.right {
  transform: rotateY(90deg) translateZ(150px);
}

.back {
  transform: rotateY(180deg) translateZ(150px);
}

.left {
  transform: rotateY(-90deg) translateZ(150px);
}

.top {
  transform: rotateX(90deg) translateZ(150px);
}

.bottom {
  transform: rotateX(-90deg) translateZ(150px);
  padding: 0;
  background-color: #fcecbc91;
  border: 1px solid #eaeaea;
}

.slider {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column-reverse;
  height: 100%;
  max-height: 300px;
}
.label {

  display: block;
  font-size: 1em;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.479);
  padding: 6px 12px;
  border-radius: 30px;
}
.label:hover {
border: 1px dotted white;}

@media (max-width: 768px) { 
.component {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.slider {
  height: 100px;
  padding: 0;
  margin: 0;
}

.label {
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.479);
  padding: 6px 12px;
  border-radius: 30px;
}
.range {
  width: 80%;
  transform: rotate(0);
}
  .container {
    perspective: 700px; 
  }

  .cube {
    width: 200px; 
    height: 200px;
  }

  .face {
    width: 200px;  
    height: 200px; 
    font-size:.8em; 
  }

  .btns {
    bottom: 18px; 
    width: 70%; 
    height: 25px; 
    font-size: 1em;
  }

  .front {
    transform: rotateY(0deg) translateZ(120px); 
  }

  .right  {
    transform: rotateY(90deg) translateZ(100px); 
  }

  .left  {
    transform: rotateY(-90deg) translateZ(100px); 
  }

  .top {
    transform: rotateX(90deg) translateZ(100px); 
  }

  .back {
    transform: rotateX(-180deg) translateZ(100px); 
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(100px); 
  }
}

.container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-height: 800px;
}

.soon{
  margin-top: 100px;
  margin-bottom: 50px;
  background-color: rgba(17, 176, 158, 0.502);
  padding: 10px 52px;
  border-radius: 20px;
}

.video {
  border-radius: 70px;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 2px 2px .4px 2px #333;
}

.text {
  font-size: 16px;
  margin: 0;
  padding: 0;
    font-weight: 300;

}
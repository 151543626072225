.mainContainer {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landingSection {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  
}


.backgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
  background-position: bottom;
  
  z-index: -1;
}

.ctaButton {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {

  .mainContainer {
    overflow: auto;
    height: auto;
  }

  .landingSection {
    height: auto;
  }




}
.container {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 22px;
}
.info {
  max-height: 300px;
  display: flex;
  flex-direction: row;
  padding: 12px;
}

.blogImage {
  height: 100px;
  width: 100px;
  padding: 12px;
}

.author {
  padding: 0 12px;
}

.text {
  height: 1000px;
  width: 100%;
}

.notebook {
  color: black;
}

.tag {
  display: inline-block;
  margin: 5px 5px 5px 0;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #0077b6;
  color: #fff;
  border-radius: 3px;
  font-family: 'Lora', sans-serif;
  box-shadow: 1px 1px 2px .5px #00121c61;
}

/* Estilo para o contêiner de passos */
.step {
  margin-top: 20px;
  padding: 10px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  background-color: #f7f7f7; 
  font-size: 0.8rem;
}

/* Estilo para os elementos <pre> */
.step pre {
  background-color: #181717; 
  color: #e6ec93; 
  padding: 18px; 
  border-radius: 5px; 
  overflow: auto; 
  white-space: pre-wrap; 
  font-family: 'Courier New', Monaco, Consolas, 'Courier', monospace; /* Fonte monoespaçada */
  line-height: 1.5; 
  position: relative;
}

/* Estilo para os elementos <code> */
.step code {
  background-color: #181717; 
  color: #e6ec93; 
  padding: 15px; 
  border-radius: 3px; 
  font-family: 'Courier New', Monaco, Consolas, 'Courier', monospace; 
  line-height: 1.5; 
}
.copyBtn {
  background-color: #f0f0f0; 
  border: 1px solid #ccc; 
  border-radius: 3px; 
  padding: 4px 8px; 
  font-size: 12px; 
  cursor: pointer; 
  margin-right: 24px; 
  margin-top: 4px;
  opacity: 0.7; 
  transition: opacity 0.3s;
  position: absolute;
  right: 0;
}

.copyBtn:hover {
  opacity: 1;
  background-color: #e0e0e0; 
}

.ending {
  padding: 22px;
}

@media (max-width: 768px) {
  .container {
    margin: 10px;
  }
  .content {
    padding: 12px;
  }
  .info {
    padding: 8px;
  }
  .blogImage {
    height: 80px;
    width: 80px;
    padding: 8px;
  }
  .author {
    padding: 0 8px;
  }
  .text {
    height: 1000px;
    width: 100%;
  }
  .tag {
    font-size: 12px;
  }
  .step {
    margin-top: 10px;
    padding: 8px; 
    font-size: 0.7rem;
  }
  .step pre {
    padding: 18px; 
  }
  .step code {
    padding: 8px; 
  }
  .copyBtn {
    padding: 2px 4px; 
    font-size: 10px; 
    margin-right: 12px; 
    margin-top: 2px;
  }
  .ending {
    padding: 12px;
  }
}
/* ContactButton.module.css */



/* Modal Styling */
.contactModal {
  background-color: #011d3cc1;
  border-radius: 10px;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height:100%;
  box-shadow: 0 0 20px rgba(79, 47, 47, 0.544);
  transition: transform 0.3s, opacity 0.3s;
  padding: 28px 28px;
  box-sizing: border-box;
}

.contactModal .title {
  font-size: 1rem;
  color: #fefefe;
  text-shadow: 1px 1px 3px #000;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #eaeaea;
  margin: 0 auto;
  padding-bottom: 8px;
}

.contactModal ul {
  list-style: none;
  padding: 0;
}

.lista {
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
  transition: background-color 0.1s;
  justify-content: center;
  gap: 1rem;
  margin-top: 10%;
}

.li {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin: 0;
  transition: background-color 0.1s;
  height: 30px;
  width: 30px;
}

.icon {
  color: #fefefe;
  
}
.li:hover {
  background-color: #02818695;
  border-radius: 50%;
}

.link:hover {
  color: #fdfeffd5;
  opacity: 1;
  border-radius: 30px;

}

.li img, .li svg {
  width: 2.8em; 
  height: 2.8em;
  padding: 2px;
}

@media (max-width: 768px) {

  .contactModal .title {
    font-size: .8rem;
    padding: 0;
    margin: 0;
    height: auto;
  }
  
.li {
  height: auto;
  width: auto;
  padding: 4px;
  margin-top: 4px;
}

  .li img, .li svg {
    width: 1.8em; 
    height: 1.8em;
  }

}

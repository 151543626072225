/* Footer.module.css */
.footer {
  color: #aaa;

  background-color: #222;
  text-align: center;
  padding: 2px 0;
  height: 70px;
  position: relative; /* Apenas relative para que o flex funcione corretamente */
  width: 100%;
  font-size: .9rem;
  box-shadow: 0 -4px 12px 0 #0000008d;
}
.privacy {
  margin: 0;
  padding: 0;
  color: #dfdfdf;
}

.privacy:visited {
  color: #faf6f6;

}

.privacy:link,
.privacy:hover {
  text-decoration: none;
  color: #9ddef0;
}



.footer p {
  margin: 0;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 0;
  color: #aaa;
}
@media (max-width: 768px) {
  .footer {
  font-size: .8rem;
}
}
.header {
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
}

.header .logo {
  font-size: 24px;
  font-weight: bold;
}

.header nav {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.header nav a {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: #fff;
  padding: 0 10px;
  transition: color 0.3s; /* Smooth color transition for hover and active link */
  font-weight: 100;
}

.header nav a.active-link {
  color: #ffa500; /* An orange color for the active link, but you can change to another color */
}

.header nav a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.header nav a:hover {
  color: #ffa500; /* Add color transition on hover for consistency with active link */
}

.header nav a:hover::before {
  transform: translateX(0);
}

.activeLink {
  color: #ffa500 !important; /* Cor laranja ou outra cor que você prefira */
}

@media  (max-width: 768px) {
  .header {
    padding: 10px 0;
  }

  .header .logo {
    font-size: 20px;
  }

  .header nav {
    gap: 12px;
  }

  .header nav a {
    padding: 0 5px;
  }
}
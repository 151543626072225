.mainContainer {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  gap: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landingSection {
  color: #fff;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content:flex-start;
  
}
.sectionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.backgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
  background-position: bottom;
  
  z-index: -1;
}

.ctaButton {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #ddd;
}


.subtitle {
  font-size: 18px;
  color: #010002;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: justify;
  padding: 12px;
  text-shadow: 
  -2px -2px 9px #9b500e,  
  0px -3px 2px #ab7878,
  -2px 2px 0 hsl(26, 91%, 34%),
  2px 2px 10px #967104;
  position: absolute;
  top: 12%;
  margin-top: 20px;
}

@media (max-width: 768px) {

  .mainContainer {
    overflow: auto;
    height: auto;
  }

  .landingSection {
    height: auto;
  }

  .subtitle {
    top: 2%;
    padding: 26px;
    width: 80%;
  }



}
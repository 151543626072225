@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

.cvContainer {
  font-family: 'Roboto', sans-serif;
  max-width: 800px;
  margin: 30px auto;
  padding: 25px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 10px; 
  text-align: justify;
}

.languageSwitch {
  display: block;
  background-color: #4CAF50;
  color: #ffffff;
  padding: 10px 20px; /* a bit more padding */
  border: none;
  font-weight: 500; /* bolder text */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  width: 200px;
  height: 35px;
}

.languageSwitch:hover {
  background-color: #45a049;
  transform: translateY(-2px); /* subtle lift on hover */
}


.buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 20px;
}

.downloadButton {
  display: block;
  background-color: #0099ff; /* cor azul */
  color: #ffffff;
  padding: 10px 20px; /* padding */
  border: none;
  font-weight: 500; /* negrito */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 35px;
}

.downloadButton:hover {
  background-color: #007bbd; /* cor azul mais escura */
  transform: translateY(-2px); /* sutil levantamento ao passar o mouse */
}

.avatarContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  gap: 18%;
  padding: 10px 20px;
  background: #cf6037;
  animation: gradientBG 7s ease infinite;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}



.titleAnimated {
  font-weight: bold;
  font-size: 2rem;
  color: #fff;
  white-space: nowrap;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.section {
  margin-top: 20px; 
  background-color: #f0f0f0; /* Example of subtle background */
  padding: 20px; 
  border-radius: 5px;
}


.subtitle {
  font-family: 'Lora', serif;
  color: #4a4a4a;
  border-bottom: 2px solid #4CAF50;
  padding-bottom: 8px;
  font-size: 1.4em;
}

.h3 {
  color: #4a4a4a;
  font-family: 'Roboto', sans-serif;

}

.content {
  font-size: 16px;
  line-height: 1.7;
  color: #555;
  margin-top: 20px;
  text-align: justify; 
}


.experience, .education {
  margin-bottom: 25px;
}

.languageList {
  line-height: 1.5;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #4a4a4a;
}

.tech {
  word-spacing: 4px;
  font-weight: 700;
}

@media (max-width: 768px) {
.avatarContainer {


justify-content: center;
  gap: 10px;
}

  .titleAnimated {
    
    padding: 0;
    text-align: center;
    margin: auto;
  }
  .section {
    margin-top: 4px; 
    background-color: #f0f0f0; /* Example of subtle background */
    padding: 12px; 
    border-radius: 5px;
  }
  .cvContainer {
    max-width: 100%	;
    margin-top: 40px;
    padding: 12px;
  }

  .buttons {
    padding: 0;
    vertical-align: middle;
  }

  .languageSwitch, .downloadButton {
    max-width: 120px;
    padding: 2px 6px;
    height: 55px;

  }
}
.blogs {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  max-height: 100%;  
  overflow: hidden;
  width: 100%;
  gap: 30px;
}

.blogContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: #8b0c0c;
  overflow: hidden;
  position: relative;
}

.titleAnimated {
  font-weight: bold;
  font-size: 2rem;
  color: #fff; 
  padding: 10px 20px;
  background: linear-gradient(45deg, #0099ff, #5100ff, #910b60, #ff2200);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  text-align: center;
  width: 100%;
  margin: 0;
  position: relative;
  top:40px;
  z-index: 2;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.input {
  position:absolute;
  top: 120px;
  left: 10%;
  width: 300px;
  max-width: 30%;
  padding: 10px 15px;
  margin: 0;
  font-size: 16px;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
  z-index: 3;
}

.input:focus {
  border-color: #0077b6;
}

.input::placeholder {
  color: #b0b0b0;
}

@media (max-width: 768px) {
  .blogs {
    flex-direction: column;
    overflow: hidden;
    max-height: 100vh;
    width: 100%;
  }

  .input {
    top: 45px;
    position: static;
    margin-top: 45px;
    width: 80%;
    max-width: 80%;
    margin-left: 20px;
  }
}

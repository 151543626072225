

/* styles.css */
body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
}
.main-container, 
.cv-container,
.blog-container,
.dev-projects-container,
.music-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    height: 100vh;
}

h1 {
    color: #333;
    font-weight: bold;
}

p {
    color: #666;
}

.header {
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.header .logo {
  font-size: 24px;
  font-weight: bold;
}

.header nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

/* styles.css */

.header nav a {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: #fff;
  padding: 0 10px;
}

.header nav a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.header nav a:hover::before {
  transform: translateX(0);
}


.hero-content {
  text-align: center;
  padding: 100px 0;
  ;
  color: #fff;
  
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-content p {
 overflow-y: hidden; 
 font-size: 1.4rem;
}



.blog-card {
  position: relative;
}

.blog-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  transform: translateY(100%);
  transition: transform 0.3s;
}

.blog-card:hover .blog-description {
  transform: translateY(0);
}

.sliderContainer {
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.slider {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  height: 100%;
  width: 100%;
}

.slide {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.navigation button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.navigation button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
  .navigation {
    top: auto;
    bottom: 20px;
  }

  .navigation button {
    color: rgba(255, 255, 255, 0.658);
  }
}

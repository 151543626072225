/* ContactButton.module.css */

/* Floating Button */
.contactFloatBtn {
  position: fixed;
  bottom: 80px;
  right: 50px;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(234, 232, 232, 0.315);
  transition: transform 0.2s, opacity 0.3s;
  cursor: pointer;
  z-index: 999;
  background: url("../../assets/images/contact2.png") no-repeat center center;
  background-size: cover;
  width: 100px;
  height: 100px;
}

.contactFloatBtn:hover {
  transform: scale(1.05);
}

/* Modal Styling */
.contactModal {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 25px;
  max-width: 400px;
  box-shadow: 0 0 20px rgba(79, 47, 47, 0.544);
  transition: transform 0.3s, opacity 0.3s;
  z-index: 1000;
}

.modalVisible {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
}

.contactModal .title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.contactModal ul {
  list-style: none;
  padding: 0;
}

.li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.li:hover {
  background-color: #f6f6f6;
  border-radius: 15px;
}

.link {
  color: #333;
  text-decoration: none;
  transition: color 0.2s;
  flex: 1; /* ensures link stretches across the entire li */
  margin-left: 10px;
}

.link:hover {
  color: #007bff;
}

.li img {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

@media (max-width: 768px) {
  
  .contactFloatBtn {
    position:fixed;
    bottom: 80px;
    right: 30px;
    width: 80px;
    height: 80px;
  }
}

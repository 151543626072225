.container {
  position: relative;
  display: inline-block;
}

.menuIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: none;
  position: fixed;
  top: 90px;
  right: 10px;
}

.buttonWrapper {
  position: fixed;
  top: 90px;
  right: -150px;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.buttonWrapper.visible {
  right: 0px; 
}

.desktopButton {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 12px;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.desktoButton:hover {
  background-color: #ffa500;
  border-color: #ffa500;
  color: #333;
}

.desktopButton:focus {
  outline: none;
}

.button {
  background-color: #5f5f5f;
  border: 1px solid #fff;
  border-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  right: 0;
  margin: 0;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 50px 0 10px 10px #420b58;
}
.flag {
  width: 12px;
  height: 9px;
  margin-right: 5px;
}

.desktopButton {
  display: none;
}

@media (max-width: 768px) {
  .menuIcon {
    display: inline-block;
  }

  .desktopButton {
    display: none; 
  }
}

@media (min-width: 769px) {
  .menuIcon {
    display: none; 
  }

    
  .buttonWrapper {
    display: none; 
  }

  .desktopButton {
    display: inline-block; 
  }
}
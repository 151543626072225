.projectCard {
    margin: 20px 10px;
    border: none;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: -2px -2px 7px 0cqw rgba(30, 1, 44, 0.881);
    padding: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: #dadada;
    max-width: 800px;
}

.title {
  height: 50px;
  color: #e0c985;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  background-color: #0f0b32;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-family: 'Courier New', Courier, monospace;
  letter-spacing: 50%;
}

.linkText {
  font-weight: 400;
  display: inline-block;
  margin-top: 10px;
  padding: 7px 15px;
  border: none;
  border-radius: 4px;
  background-color: #172330b1;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}
.linkText:hover {
  background-color: #0056b3;
}
.link:visited {
  text-decoration: none;
}

.link:link {
  text-decoration: none;
} 

.iframe {
  border-radius: 0px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(116, 36, 229, 0.853);
  overflow-x: hidden;
  overflow-y: hidden;

}
/* Custom scrollbar*/
.iframe iframe::-webkit-scrollbar {
  width: 8px;
}

.iframe iframe::-webkit-scrollbar-track {
  background-color: rgba(30, 56, 105, 0.493); 
  border-radius: 10px;
}

.iframe iframe::-webkit-scrollbar-thumb {
  background-color: #3498db; 
  border-radius: 10px;
}

.iframe iframe::-webkit-scrollbar-thumb:hover {
  background-color: #2980b9; 
}

.iframe iframe::-webkit-scrollbar-thumb:active {
  background-color: #1a5276; 
}

@media (max-width:768px){

  .projectCard {
    max-width: 80%;
    max-height: 300px;
    margin-bottom: 40px;
  }

  .title {
    height: 30px;
    font-size: 16px;
    padding: 10px;
    text-align: left;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");

*:link,
*:visited {
  text-decoration: none;
  color: #ffffff;
}

.blogPreview {
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  overflow-x: hidden;
  background-color: #fff;
  transition: transform 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  height: 700px;
  overflow: hidden;
  margin: 160px auto;
  margin-bottom: 120px ;
}

.blogPreview:hover {
  background-color: s#7a740048;
}

.blogImage {
  width: 100%;
  height: 300px;
  object-fit: contain;
  align-self: center;
  margin: 12px 0;
}

.blogPreview h3 {
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 20px;
  color: #333;
}

.blogPreview p {
  padding: 0 40px;
  color: #555;
  line-height: 1.6;
  font-family: "Roboto", sans-serif;
  text-align: left;
  overflow-y: auto;
}

.blogPreview > small {
  display: block;
  padding: 0 50px;
  font-size: 14px;
  color: #777;
  font-family: "Lora", sans-serif;
}

.tag {
  display: inline-block;
  margin: 5px 5px 5px 0;
  padding: 5px 12px;
  font-size: 14px;
  background-color: #0077b6;
  color: #fff;
  border-radius: 3px;
  font-family: "Lora", sans-serif;
  box-shadow: 1px 1px 2px 0.5px #00121c61;
}
.tags {
  color: #333;
  padding: 12px;
}

.blogPreview p::-webkit-scrollbar {
  width: 8px;
}

.blogPreview p::-webkit-scrollbar-track {
  background-color: rgba(30, 56, 105, 0.493); 
  border-radius: 10px;
}

.blogPreview p::-webkit-scrollbar-thumb {
  background-color: #3498db; 
  border-radius: 10px;
}

.blogPreview p::-webkit-scrollbar-thumb:hover {
  background-color: #2980b9; /* Darker blue when hovered */
}

.blogPreview p::-webkit-scrollbar-thumb:active {
  background-color: #1a5276; /* Even darker blue when active */
}

.header {
  padding: 12px;
}

@media (max-width: 768px) {
  .blogPreview {
    max-width: 80%;
    margin: 140px auto ;
    margin-bottom: 260px;
  }

  .header {
    flex-direction: row;
    
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;}

  .blogImage {
    height: 200px;
    width: 100%;
  }

  .blogPreview h3 {
    font-size: 16px;
  }

  .blogPreview p {
    font-size: 12px;
    padding: 0 20px;
  }

  .blogPreview > small {
    font-size: 12px;
  }

  .tag {
    font-size: 12px;
    padding: 4px;
  }

  .tags {
    font-size: 14px;
  }
}

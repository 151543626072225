
.musicPage {
  height: 100vh;
  max-height: 100vh;
  background-color: #05091d;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: bold;
  font-size: 2rem; /* adjust as necessary */
  color: #fff; /* adjust as necessary */
  padding: 10px 20px;
  background: linear-gradient(45deg, #0e1bd0,#0099ff, #8400ff, #ff2200);
  background-size: 400% 400%;
  animation: gradientBG 12s ease infinite;
  text-align: center;
  width: 100%;
  margin: 0;
  position: absolute;
  top:40px;
  z-index: 5;
}


@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
.musicPage {
  overflow-y: auto;
  height: 200vh;
  max-width: auto;
}
}

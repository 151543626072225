.titleAnimated {
  font-weight: bold;
  font-size: 2rem;
  color: #fff; 
  padding: 10px 0;
  background: linear-gradient(45deg, #5840a0, #a803a8, #9e0067, #5840a0);
  background-size: 400% 400%;
  animation: gradientBG 12s ease infinite;
  width: 100%;
  margin: auto;
  margin-top: 42px;
  position: relative;
box-shadow: 0 2px 6px 2px #4b3689}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.projectsContainer {
  margin: auto;
  padding: 0;
  text-align: center;
  background-color: #23164a;
}

.projectsList {
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    margin: auto;
    max-width: 1080px;
}

@media (max-width: 768px) {
  .projectsContainer {
    display: flex;
    flex-direction: column;
    padding: 0;

  }

  .projectsList {
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow-x: hidden;
    padding: 0;
    align-items: center;
    width: 400px;	
    max-width: 90%;

  }
}



.sliderContainer {
  overflow-x: hidden;
}

.sectionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: hidden;
  gap: 10px;
  padding: 50px;
  flex-direction: row;

}

.sectionIcon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.sectionButton {
  display: inline-block; /* To allow padding */
  background-color: #333;
  color: #fff;
  text-decoration: none; /* Remove underline */
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; 
  font-size: .8rem;
}

.sectionButton:link,
.sectionButton:visited  {
color: #fff;
}


.sectionButton:hover {
  background-color: #555;
}

.sectionTitle {
  font-size: .8rem;
  white-space: nowrap;
}

.sectionContent {
  font-size: .8rem;
  margin-top: 10px;
  text-align: center;
  height: 30%;
  max-width: 90%;
}
.cvSection,
.blogSection,
.projectsSection,
.musicSection {
  padding: 20px;
  text-align: center;
  background-color: #eaeaeaa8;
  border-radius: 10px;
  height: 230px;
  position: relative;
  width: 320px;
}

@media (min-width:769px) and (max-width: 1480px) {
  .sectionsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
    height: 70vh;
    overflow-y: hidden;
  }
  .cvSection,
  .blogSection,
  .projectsSection,
  .musicSection {
    padding: 20px;
    text-align: center;
    background-color: #eaeaeaa8;
    border-radius: 10px;
    height: 230px;
    line-height: 1.2;
    position: relative;

  }

  .sectionContent {
    height: 70px;
  }

  .sectionButton {
    padding: 10px 12px;

  }
}

@media (max-width: 768px) {
  .sectionsContainer {
    flex-direction: column;  
    overflow-y: scroll;  
    margin-top: 0px;
    height: auto;
    max-height: auto; 
    max-width: 900px;
    overflow-y: hidden;
  }
  
  .cvSection,
  .blogSection,
  .projectsSection,
  .musicSection {
    padding: 20px 0;
    margin: auto;
    text-align: center;
    background-color: #eaeaeaa8;
    border-radius: 10px;
    height: 230px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap:0;
  }

  .cvSection p,
  .blogSection p,
  .projectsSection p,
  .musicSection p{
    padding: 20px 0;
  }
  .sliderContainer {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  
  .sliderContainer > section {
    flex: 0 0 100%;
    scroll-snap-align: start;
    box-sizing: border-box;
    padding: 20px;
  }
  
}

.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 100%;
  width: 100%;
  margin: 0;
  height: auto;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 20px 0 20px;
  overflow: hidden;
  position: relative;
}

.titleContainer {
  font-weight: bold;
  font-size: 2rem;
  color: #fff;
  background: transparent;
  text-align: center;
  box-sizing: border-box;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100% !important;
  z-index: 0;

}

.titleBorder2,
.titleBorder3,
.titleBorder {
  font-weight: bold;
  color: #fff;
  background: transparent;
  text-align: center;
  box-sizing: border-box;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100% !important;
  z-index: 0;
}

.titleAnimated {
  font-weight: bold;
  font-size: 2rem;
  color: #ffeddc;
  text-shadow: 0px 0px 3px 23px #fff;
  text-shadow: 
  -2px -2px 0 #042400,  
  2px -2px 0 #1f013b,
  -2px 2px 0 #240000,
  1px 2px 0 #006817; /* Adiciona uma borda preta ao redor das letras */
  text-align: center;
  box-sizing: border-box;
  max-height: 50px;
  margin-top: 60px;
  z-index: 1;
}


.subtitle {
  color: #f9eeff;
  letter-spacing: 1px;
  font-weight: 800;
  padding: 0 20px 0 20px;
  text-shadow: 
  -2px -2px 19px #62009b,  
  0px -3px 2px #2fd437,
  -2px 2px 0 hsl(0, 75%, 55%),
  2px 2px 10px #57005f;
}

.titleBorder2,
.titleBorder3::before{
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 100% 0 100% 0;
    border: 3px solid transparent;
    background: linear-gradient(45deg, #0099ff, #7a32ff, #ff4400, #ff0033);
    background-size: 400% 400%;
    -webkit-mask: 
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    mask: 
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude;
    animation: gradientBorder 8s ease-in-out infinite;
    pointer-events: none;
}

.titleBorder3::before{
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 100% 0 100% 0;
  border: 3px solid transparent;
  background: linear-gradient(45deg, #0099ff, #7a32ff, #ff4400, #ff0033);
  background-size: 400% 400%;
  -webkit-mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude;
  animation: gradientBorder 4s ease-in-out infinite;
  pointer-events: none;
}

.titleContainer::before{
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 100% 0 100% 0;
  border: 3px solid transparent;
  background: linear-gradient(45deg, #0099ff, #7a32ff, #ff4400, #ff0033);
  background-size: 400% 400%;
  -webkit-mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude;
  animation: gradientBorder 6s ease-in-out infinite;
  pointer-events: none;
}


.titleBorder::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 100% 0 100% 0;
  border: 3px solid transparent;
  background: linear-gradient(45deg, #0099ff, #7a32ff, #ff4400, #ff0033);
  background-size: 400% 400%;
  -webkit-mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude;
  animation: gradientBorder 5s ease-in-out infinite;
  pointer-events: none;
}

@keyframes gradientBorder {
  0% {
    background-position: 0% 50%;
    border-top-right-radius: 100% ;
    border-bottom-right-radius: 0% ;
    border-bottom-left-radius: 100% ;
    border-top-left-radius: 0% ;


  }
  50% {
    background-position: 100% 50%;
    border-top-right-radius: 0% ;
    border-bottom-right-radius: 100% ;
    border-bottom-left-radius: 0% ;
    border-top-left-radius: 100% ;


  }
  100% {
    background-position: 0% 50%;
    border-top-right-radius: 100% ;
    border-bottom-right-radius: 0% ;
    border-bottom-left-radius: 100% ;
    border-top-left-radius: 0% ;
  }
}

@media screen and (max-width: 768px) {
  .heroContainer {
    margin: auto;
    margin-top: 0;
    height: 300px;
    display: flex;
    gap: 15%;
    ;
  }

  .titleAnimated {
    font-size: 1.6rem;
    max-width: 95%;	
  }

  .subtitle {
    height: 50px;
    font-size: 1rem;
  } 

}

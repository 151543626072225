.avatarContainer {
  width: 120px;
  height: 120px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 12px 2px rgba(177, 136, 2, 0.6);
}

@media (max-width: 768px) {
  .avatarContainer {
    width: 100px;
    height: 100px;
  }

  .avatar {
    width: 100px;
    height: 100px;
  }
}
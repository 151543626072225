
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 100%;
  width: 120%;
  margin: auto;
  height: 80%;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 20px 0 20px;
  overflow: hidden;
  position: relative;

}

.titleContainer {
  font-weight: bold;
  font-size: 1.2rem;
  color: #fff;
  background: transparent;
  text-align: center;
  box-sizing: border-box;
  height: auto; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;    /* Largura relativa */
  z-index: 0;
  max-height: 100%;
  max-width: 100%; 
}

.titleBorder2,
.titleBorder3,
.titleBorder {
  font-weight: bold;
  color: #fff;
  background: transparent;
  text-align: center;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 0;
  opacity: .4;
  width: 90%; /* Largura relativa */

}

.titleAnimated {
  font-weight: bold;
  font-size: 1.2rem;
  color: #ffffff; 
  text-shadow:
    /* Sombra interna dourada suave */
    -1px -1px 0px #b39700, 
    1px -1px 0px #b39700,
    -1px 1px 0px #b39700,
    1px 1px 0px #b39700,

    /* Brilho externo dourado */
    0px 0px 5px #ffd700,
    0px 0px 10px #ffd700; 

  text-align: center;
  
  box-sizing: border-box;
  height: auto;
  margin: auto;
  z-index: 1;
  transition: all 0.6s;
  
}

.titleAnimated:hover {
  color: #ffffff;
  text-shadow: 
    /* Sombra interna dourada mais intensa */
    -1px -1px 0px #d4af37,
    1px -1px 0px #d4af37,
    -1px 1px 0px #d4af37,
    1px 1px 0px #d4af37,

    /* Brilho externo dourado mais intenso e espalhado */
    0px 0px 8px #ffd700,
    0px 0px 15px #ffd700,
    0px 0px 20px rgba(255, 215, 0, 0.5); 
    

}



.titleBorder2,
.titleBorder3::before{
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 100% 0 100% 0;
    border: 3px solid transparent;
    background: linear-gradient(45deg, #0099ff, #7a32ff, #ff4400, #ff0033);
    background-size: 400% 400%;
    -webkit-mask: 
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    mask: 
      linear-gradient(#fff 0 0) padding-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude;
    animation: gradientBorder 8s ease-in-out infinite;
    pointer-events: none;
    
}

.titleBorder3::before{
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 100% 0 100% 0;
  border: 3px solid transparent;
  background: linear-gradient(45deg, #0099ff, #7a32ff, #ff4400, #ff0033);
  background-size: 400% 400%;
  -webkit-mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude;
  animation: gradientBorder 4s ease-in-out infinite;
  pointer-events: none;
  
}

.titleContainer::before{
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 100% 0 100% 0;
  border: 3px solid transparent;
  background: linear-gradient(45deg, #0099ff, #7a32ff, #ff4400, #ff0033);
  background-size: 400% 400%;
  -webkit-mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude;
  animation: gradientBorder 6s ease-in-out infinite;
  pointer-events: none;
}


.titleBorder::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 100% 0 100% 0;
  border: 3px solid transparent;
  background: linear-gradient(45deg, #0099ff, #7a32ff, #ff4400, #ff0033);
  background-size: 400% 400%;
  -webkit-mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude;
  animation: gradientBorder 5s ease-in-out infinite;
  pointer-events: none;
}

@keyframes gradientBorder {
  0% {
    background-position: 0% 50%;
    border-top-right-radius: 100% ;
    border-bottom-right-radius: 0% ;
    border-bottom-left-radius: 100% ;
    border-top-left-radius: 0% ;


  }
  50% {
    background-position: 100% 50%;
    border-top-right-radius: 0% ;
    border-bottom-right-radius: 100% ;
    border-bottom-left-radius: 0% ;
    border-top-left-radius: 100% ;


  }
  100% {
    background-position: 0% 50%;
    border-top-right-radius: 100% ;
    border-bottom-right-radius: 0% ;
    border-bottom-left-radius: 100% ;
    border-top-left-radius: 0% ;
  }
}

@media screen and (max-width: 768px) {
  .heroContainer {
    margin: auto;
    width: 90%; 
    height: 90%;
    display: flex;
    
    
  }

  .titleContainer {
    width: 100%; 
    max-width: 100%; 
  }

  .titleBorder,
  .titleBorder2,
  .titleBorder3 {
    width: 70%;
    height: 90%;
  }

  .titleAnimated {
    padding: 0px;
    font-size: 1.1rem;
    text-align: left;
  }

}
